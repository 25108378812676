import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useState, useMemo, memo } from "react"
import { GalleryDialogProps } from "./GalleryDialogProps"
import { Hashicon } from "@emeraldpay/hashicon-react"
import { WordData } from "../model/WordData"
import { WordProfileDialog } from "./WordProfileDialog"
import { FixedSizeGrid as Grid } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

const COLUMN_WIDTH = 120; // Adjusted for card size + padding
const ROW_HEIGHT = 140;   // Adjusted for card size + padding
const MIN_COLUMNS = 3;    // Minimum number of columns

// Memoized word card component
const WordCard = memo(({ word, onOpenProfile }: { word: WordData; onOpenProfile: (w: WordData) => void }) => {
  if (!word?.word) return null;
  
  return (
    <Tooltip arrow title={word.trans ?? ""}>
      <IconButton
        onClick={() => onOpenProfile(word)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
          minWidth: "80px",
          minHeight: "100px",
          border: "1px solid lightgray",
          borderRadius: "8px",
          cursor: "pointer",
          "&:hover": { boxShadow: 3 },
        }}
      >
        <Hashicon value={word.word} size={40} />
        <Typography color="text.primary">{word.word}</Typography>
      </IconButton>
    </Tooltip>
  );
});

// @ts-ignore
const Cell = memo(({ columnIndex, rowIndex, style, data }) => {
  const { words, onOpenProfile, columnCount } = data;
  const index = rowIndex * columnCount + columnIndex;
  const word = words[index];

  if (!word) return null;

  return (
    <Box sx={{ ...style, p: 1 }}>
      <WordCard word={word} onOpenProfile={onOpenProfile} />
    </Box>
  );
});

export function GalleryDialog(props: GalleryDialogProps) {
  const { open, words, handleClose, activeCollection } = props;
  const [selectedWord, setSelectedWord] = useState<WordData | null>(null);

  const validWords = useMemo(() => 
    words.filter(w => w?.word), 
    [words]
  );

  const handleOpenWordProfile = useMemo(
    () => (word: WordData) => setSelectedWord(word),
    []
  );

  const handleCloseWordProfile = () => {
    setSelectedWord(null);
  };

  return (
    <Dialog open={open} maxWidth="xl" fullWidth>
      <DialogTitle>Word Gallery</DialogTitle>
      <DialogContent sx={{ height: '80vh' }}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            const columnCount = Math.max(MIN_COLUMNS, Math.floor(width / COLUMN_WIDTH));
            const rowCount = Math.ceil(validWords.length / columnCount);
            
            const itemData = {
              words: validWords,
              onOpenProfile: handleOpenWordProfile,
              columnCount,
            };

            return (
              <Grid
                columnCount={columnCount}
                columnWidth={COLUMN_WIDTH}
                height={height}
                rowCount={rowCount}
                rowHeight={ROW_HEIGHT}
                width={width}
                itemData={itemData}
              >
                {Cell}
              </Grid>
            );
          }}
        </AutoSizer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

      {selectedWord && (
        <WordProfileDialog
          open={!!selectedWord}
          word={selectedWord}
          handleClose={handleCloseWordProfile}
          activeCollection={activeCollection}
        />
      )}
    </Dialog>
  );
}
